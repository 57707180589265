export const STATUS = {
  DATA_ASSESSMENT: 'data assessment',
  TECHNICAL_EVALUATION: 'technical evaluation',
  MPE_REVIEW : 'mpe review',
  REVIEW: 'review',
  ACCEPTED: 'accepted',
  DELIVERED: 'delivered',
  CLOSED: 'closed'
};

export const ACTIONS = {
  ACCEPT    : 'accept',
  REJECT    : 'reject',
  REVIEW    : 'review',
  DOWNLOAD  : 'download',
  REPUDIATE : 'repudiate',
  REVOKE : 'technical evaluation',
  MOVETOPLASMA : 'move-to-plasma'
};

export const ROLES = {
  onlyChairman: ['chairman', 'superuser'],
  onlySpecialist: ['specialist', 'superuser'],
  onlyContributor: ['mpe', 'superuser'],
  onlyValidator: ['validator', 'superuser'],
  chairmandAndSpecialist: ['chairman', 'specialist', 'superuser'],
  superuser: ['superuser']
}

export const CARDINALITIES = ['1_n', 'n_1', 'n_n','n'];

export const BUTTONS = [
  { key: [ACTIONS.MOVETOPLASMA], label: 'Move to Plasma', roles: ROLES.onlyChairman, status: [STATUS.DELIVERED, STATUS.CLOSED], toolTip:['Move the delivered dossier to PLASMA','To move the dossier to Plasma, exercise must be re-opened and\ndossier must be in \'Delivered\' status.\nGo to Activity Tracking dashboard to Re-Open the exercise']},
  { key: [ACTIONS.ACCEPT], label: 'Accept Dossier', roles: ROLES.onlyValidator, status: [STATUS.REVIEW],toolTip:'' },
  { key: [ACTIONS.REVIEW], label: 'Submit for Acceptance/Validation', roles: ROLES.onlySpecialist, status: [STATUS.TECHNICAL_EVALUATION], toolTip:'' },
  { key: [ACTIONS.REJECT], label: 'Reject Dossier', roles: ROLES.onlyValidator, status: [STATUS.REVIEW], toolTip:'' },
  { key: [ACTIONS.DOWNLOAD], label: 'Download', roles: ROLES.onlyChairman, status: [STATUS.ACCEPTED, STATUS.DELIVERED, STATUS.CLOSED], toolTip:''},
  { key: [ACTIONS.REPUDIATE], label: 'Redo', roles: ROLES.onlyChairman, status: [STATUS.ACCEPTED, STATUS.DELIVERED,  STATUS.CLOSED], toolTip:['Send back to Technical Evaluation', 'To redo dossier, exercise must be re-opened.\nGo to Activity Tracking dashboard to Re-Open the exercise']}
];

export const ACTION_STATUS = {
  [ACTIONS.ACCEPT]: STATUS.ACCEPTED,
  [ACTIONS.REJECT]: STATUS.TECHNICAL_EVALUATION,
  [ACTIONS.REVIEW]: STATUS.REVIEW,
  [ACTIONS.DOWNLOAD]: STATUS.DELIVERED,
  [ACTIONS.REPUDIATE]: STATUS.TECHNICAL_EVALUATION
};

export const NOTIFICATIONS = {
  error: {
    type: 'error', // red notification
    msg: {
      specialist: 'Unable to determine the dossier status.',
      mpe: 'Unable to determine the dossier status.',
      chairman: 'Unable to determine the dossier status.',
      default: 'Unable to determine the dossier status.',
      validator: 'Unable to determine the dossier status.',
      superuser: 'Unable to determine the dossier status.'
    }
  },
  accepted: {
    type: 'success', // green notification
    msg: {
      specialist: 'The dossier has been accepted.',
      mpe: 'The dossier has been accepted.',
      chairman: 'The dossier has been accepted. Please download the dossier to change the status to "Delivered".{msg1}',
      default: 'The dossier has been accepted.',
      validator: 'The dossier has been accepted.',
      superuser: 'The dossier has been accepted. Please download the dossier to change the status to "Delivered".{msg1}'
    }
  },
  technical_evaluation: {
    type: 'info', // bluegrey notification
    msg: {
      specialist: 'The dossier status is technical evaluation.{msg1} Please check the entire dossier and send it for Comments/Acceptance.',
      mpe: 'The dossier status is technical evaluation.',
      chairman: 'The dossier status is technical evaluation.',
      default: 'The dossier status is technical evaluation.',
      validator: 'The dossier status is technical evaluation.',
      superuser: 'The dossier status is technical evaluation.{msg1} Please check the entire dossier and send it for Comments/Acceptance.'
    }
  },
  rejected: {
    type: 'error', // red notification
    msg: {
      specialist: 'The dossier has been rejected. Please click on the floating tab for more information.',
      mpe: 'The dossier is rejected and it is sent back to specialist.',
      chairman: 'The dossier is rejected and it is sent back to specialist.',
      default: 'The dossier has been rejected.',
      validator: 'The dossier is rejected and it is sent back to specialist.',
      superuser: 'The dossier has been rejected. Please click on the floating tab for more information.'
    }
  },
  review: {
    type: 'warning', // yellow notification
    msg: {
      specialist: 'The dossier is under review. Please wait until it is reviewed.',
      mpe: 'The dossier is under review.',
      chairman: 'Please review the dossier.{msg1}',
      default: 'The dossier is under review.',
      validator: 'Please review the dossier and take necessary actions.{msg1}',
      superuser: 'Please review the dossier and take necessary actions.{msg1}'
    }
  },
  delivered: {
    type: 'success', // green notification
    msg: {
      specialist: 'The dossier has been delivered.',
      mpe: 'The dossier has been delivered.',
      chairman: 'The dossier has been delivered.',
      default: 'The dossier has been delivered.',
      validator: 'The dossier has been delivered.',
      superuser: 'The dossier has been delivered.'
    },
  },
  closed: {
    type: 'success', // green notification
    msg: {
      specialist: 'The dossier has been delivered and the exercise has been closed.',
      mpe: 'The dossier has been delivered and the exercise has been closed.',
      chairman: 'The dossier has been delivered and the exercise has been closed.',
      default: 'The dossier has been delivered and the exercise has been closed.',
      validator: 'The dossier has been delivered and the exercise has been closed.',
      superuser: 'The dossier has been delivered and the exercise has been closed.'
    },
  },
  mpe_review: {
    type: 'warning', // yellow notification
    msg: {
      specialist: 'The dossier is in Comments. Please wait until Comments have been added.',
      mpe: 'The dossier is in Comments.',
      chairman: 'The dossier is in Comments. Please take necessary action',
      default: 'The dossier is in Comments.',
      validator: 'The dossier is in Comments.',
      superuser: 'The dossier is in Comments. Please take necessary action.'
    }
  },
  unknown: { // unknown includes status like data assessment, closed etc
    type: 'info', // bluegrey notification
    msg: {
      specialist: 'No actions are permitted.',
      chairman: 'No actions are permitted.',
      mpe: 'No actions are permitted.',
      default: 'No actions are permitted.',
      validator: 'No actions are permitted.',
      superuser: 'No actions are permitted.',
    }
  },
  rollback_mpe_review: {
    type: 'info', // bluegrey notification
    msg: {
      specialist: 'The dossier status is technical evaluation. Revoked from Comments. Please check the entire dossier and send it for Comments/Acceptance.',
      mpe: 'The dossier status is technical evaluation. Specialist revoked from Comments',
      chairman: 'The dossier status is technical evaluation.',
      default: 'The dossier status is technical evaluation.',
      validator: 'The dossier status is technical evaluation. Specialist revoked Comments',
      superuser: 'The dossier status is technical evaluation. Revoked from Comments. Please check the entire dossier and send it for Comments/Acceptance.'
    }
  },
};

export const DOSSIER_SYNC_STATUS = {
  INIT: "INIT",
  SYNC: "SYNC",
  READY: "READY",
  FAIL: "FAIL",
  ZIPPED: "ZIPPED",
};

export const DERIVED_SYNC_STATUS = {
  UNKNOWN: "UNKNOWN",
  IN_PROGRESS: "IN_PROGRESS",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
};

export const GENERIC_NOTIFICATION_MESSAGES = {
  EDIT_SUCCESS : "Data Saved Successfully",
  FILE_SUCCESS : "File transfer initiated Successfully"
};

export const ED_DISABLE_TYPE = { INIT: "init", TOGGLE: "toggle" };

export const CONFIRMATION_MODEL_ACTION = {
  SUBMIT_WITHOUT_COMMENT : 'SUBMIT-WITHOUT-COMMENT',
  COMMENTS_DONE : 'COMMENTS-DONE',
};

export const MSG_SHOULD_SHOWON_BANNER = {
  SUBMIT_WITHOUT_COMMENT    : 'mpe review done without comments',
  SUBMIT_WITH_COMMENT       : 'mpe review done with comments',
  CHAIR_PERSON_REJECT       : 'chair person rejected the dossier'
};


export const SECTION_DROPDOWN = {
  MPE_COMMENT_KEY    : 'MPE loop',
  MPE_COMMENT_MSG    : 'Reviewed With Comments',
  MPE_NO_COMMENT_MSG : 'Reviewed Without Comments',
};


export const BANNER_MESSAGE = {
  'TE_MPE_WITHOUT_COMMENT' : ' ED submitted without comments,',
  'TE_MPE_WITH_COMMENT': ' ED submitted with comments,',
  'RV_MPE_WITHOUT_COMMENT' : ' ED submitted without comments.',
  'RV_MPE_WITH_COMMENT': ' ED submitted with comments.',
}
